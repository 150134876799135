








import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ImageMixin from '@/components/modules/drillsv2/molecules/ImageMixin'
import _ from 'lodash'
import TooltipButton from '@/components/modules/drills/atoms/TooltipButton.vue'
import DrillWebApi from '@/mixins/drillsv2/WebApi'

@Component({
  components: {
    TooltipButton,
  },
})
export default class ImageTextExplain extends Mixins(ImageMixin, DrillWebApi) {
  // 読み込み完了フラグ
  private loadComplete = false

  @Prop({ default: 0 })
  pageIndex!: number

  @Prop({ default: 1 })
  pageNum!: number

  @Prop({ default: 0 })
  questionIndex!: number

  // s3アクセス用フルURL
  private textExplainUrl: string[] = []

  private async mounted() {
    Vue.prototype.$loading.startWhite()
    // s3アクセス用URL取得
    this.textExplainUrl = await Promise.all(
      this.textExplainS(this.pageIndex, this.questionIndex).map(async (path: any) => {
        const url = await this.getImageUrl(path)
        return {
          url,
          isError: false
        }
      })
    )

    this.loadComplete = true
    Vue.prototype.$loading.complete()
  }
}
